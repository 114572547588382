<template>
	<div class="bg-gray-100">
		<!-- preliminary info -->

		<div v-if="currentObj.showSettings" class="flex flex-col justify-center items-center w-screen h-screen">
			<!-- roadmap -->
			<div class="w-full flex flex-row justify-center items-center py-4">
				<ul class="timeline">
					<li>
						<div class="timeline-start timeline-box-">Подтвердить email</div>
						<div class="timeline-middle">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-primary h-5 w-5">
								<path
									fill-rule="evenodd"
									d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
									clip-rule="evenodd"
								/>
							</svg>
						</div>
						<hr class="bg-primary-" />
					</li>
					<li>
						<hr class="bg-primary-" />
						<div class="timeline-middle">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-primary- h-5 w-5">
								<path
									fill-rule="evenodd"
									d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
									clip-rule="evenodd"
								/>
							</svg>
						</div>
						<div class="timeline-end timeline-box-">Выбрать домен</div>
						<hr class="bg-primary-" />
					</li>
					<li>
						<hr />
						<!-- <div class="timeline-start timeline-box">Apple Watch</div> -->
						<div class="timeline-start timeline-box-">Создать Супер Админа</div>
						<div class="timeline-middle">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5">
								<path
									fill-rule="evenodd"
									d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
									clip-rule="evenodd"
								/>
							</svg>
						</div>
					</li>
				</ul>
			</div>

			<!-- syperadmin registraion form -->
			<!-- <div class="h-1/2 max-w-96- w-full flex flex-row justify-center"> -->
			<div class="h-1/2 rounded- rounded-md p-4- shadow-md- lg:w-1/3 w-3/4 max-w-sm">
				<!-- <div> -->
				<!-- 	<div class="font-bold text-2xl">Почти все готово!</div> -->
				<!-- 	<div class="text-gray-400"> -->
				<!-- 		Завершите регистрацию: заполните сведения о суперадминистраторе в вашей команде и выберите имя домена под которым будет выполнена публикация. -->
				<!-- 	</div> -->
				<!-- </div> -->

				<!-- email: currentObj.newAdmin.email, -->
				<div>
					<label class="form-control- w-full max-w-xs">
						<div class="label">
							<span class="label-text">Login</span>
						</div>
						<input
							type="text"
							placeholder="Confirmed email"
							readonly
							class="input focus:input-primary input-bordered w-full max-w-sm text-gray-500"
							v-model="currentObj.newAdmin.email"
						/>
					</label>
				</div>
				<!-- password: currentObj.newAdmin.password, -->
				<!-- <div> -->
				<!-- 	<label class="form-control- w-full max-w-xs"> -->
				<!-- 		<div class="label"> -->
				<!-- 			<span class="label-text">Password</span> -->
				<!-- 		</div> -->
				<!-- 		<input type="password" placeholder="Password" class="input focus:input-primary input-bordered w-full max-w-sm text-gray-500" v-model="currentObj.newAdmin.password" /> -->
				<!-- 	</label> -->
				<!-- </div> -->
				<!-- name: currentObj.newAdmin.name, -->
				<div>
					<label class="form-control- w-full max-w-xs">
						<div class="label">
							<span class="label-text">Name*</span>
						</div>
						<input type="text" placeholder="Name" class="input focus:input-primary input-bordered w-full max-w-sm text-gray-500" v-model="currentObj.newAdmin.name" />
						<div class="label">
							<!-- <span class="label-text-alt"></span> -->
							<!-- <span class="label-text-alt">required</span> -->
						</div>
					</label>
				</div>
				<!-- full_name: currentObj.newAdmin.full_name, -->
				<div>
					<label class="form-control- w-full max-w-xs">
						<div class="label">
							<span class="label-text">Full name</span>
						</div>
						<input type="text" placeholder="Full name" class="input focus:input-primary input-bordered w-full max-w-sm text-gray-500" v-model="currentObj.newAdmin.full_name" />
					</label>
				</div>
				<!-- domain_prefix: currentObj.newInstance.domain_prefix, -->
				<div>
					<label class="form-control- w-full max-w-xs">
						<div class="label">
							<span class="label-text">Domain name*</span>
						</div>
						<div class="flex flex-row justify-start items-center gap-2">
							<input
								type="text"
								placeholder="Domain name"
								class="input focus:input-primary input-bordered w-full max-w-sm text-gray-500"
								v-model="currentObj.newInstance.domain_prefix"
								@input="onDomainInputDebounce"
							/>

							<div class="flex flex-row justify-start items-center">
								<!-- is ok -->
								<div v-if="!currentObj.domainUnavailable && domainIsValid">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 stroke-green-400">
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
										/>
									</svg>
								</div>
								<!-- is not ok -->
								<div v-if="currentObj.domainUnavailable || !domainIsValid">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 stroke-red-400">
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
										/>
									</svg>
								</div>
							</div>
						</div>
					</label>
				</div>

				<div class="flex flex-row justify-end pt-10- pt-4">
					<div class="min-w-24 flex flex-col justify-center">
						<!-- <button type="button" class="text-sm font-semibold leading-6 text-gray-900">Cancel</button> -->
						<!-- {{ domainIsValid }} -->

						<button
							class="btn btn-primary btn-sm btn-block"
							@click="onSave"
							:class="domainIsValid && !registrationAlreadyExsists && !currentObj.domainUnavailable && nameIsValid ? '' : 'btn-error bg-red-300- hover:bg-red-500'"
						>
							Создать команду
						</button>

						<!-- <button -->
						<!-- 	type="submit" -->
						<!-- 	class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" -->
						<!-- 	@click="onSave" -->
						<!-- 	:class="domainIsValid && !registrationAlreadyExsists && !currentObj.domainUnavailable ? '' : 'bg-red-300 hover:bg-red-500'" -->
						<!-- > -->
						<!-- 	<!-- :class="domainIsValid && passwordIsValid && nameIsValid && !registrationAlreadyExsists ? '' : 'bg-red-300 hover:bg-red-500'" -->
						<!-- 	Create account -->
						<!-- </button> -->
					</div>
				</div>
			</div>
		</div>

		<div v-if="currentObj.showOk">
			<AdminUserSettingsOk />
		</div>
		<div v-if="currentObj.showFalse">
			<AdminUserSettingsFalse />
		</div>
	</div>

	<div v-if="false" class="prose w-full p-2">
		<div v-if="currentObj.showSettings">
			<div>
				<h1>You are about to done...</h1>
			</div>
			<div class="w-screen">
				<div class="flex flex-row justify-center">
					<div class="w-1/2">
						<h2 class="text-base font-semibold leading-7 text-gray-900">Your email successfully confirmed</h2>
						<!-- <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> -->
						<!-- {{ registrationAlreadyExsists }} -->
						<div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
							<div class="sm:col-span-4">
								<!-- <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label> -->
								<h2>
									{{ currentObj.newAdmin.email }}
								</h2>
							</div>
						</div>

						<h2 class="text-base font-semibold leading-7 text-gray-900">Final step is to set your team domain name</h2>
						<!-- <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> -->

						<div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
							<div class="sm:col-span-4">
								<label for="team_name" class="block text-sm font-medium leading-6 text-gray-900">Team name(domain prefix)</label>
								<div class="mt-2 flex flex-row gap-2">
									<input
										id="team_name"
										name="team_name"
										type="text"
										autocomplete="team_name"
										class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
										v-model="currentObj.newInstance.domain_prefix"
										@input="onDomainInputDebounce"
									/>
									<div class="flex flex-col justify-center">
										<!-- is ok -->
										<div v-if="!currentObj.domainUnavailable && domainIsValid">
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 stroke-green-400">
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
												/>
											</svg>
										</div>
										<!-- is not ok -->
										<div v-if="currentObj.domainUnavailable || !domainIsValid">
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 stroke-red-400">
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
												/>
											</svg>
										</div>
									</div>
								</div>
								<div>
									{{ currentObj.newInstance.domain_prefix }}.exlnt.team
									<!-- <div>{{ currentObj.domainUnavailable }}</div> -->
								</div>
							</div>
						</div>

						<div class="flex flex-row justify-end pt-10">
							<div class="min-w-24 flex flex-col justify-center">
								<!-- <button type="button" class="text-sm font-semibold leading-6 text-gray-900">Cancel</button> -->
								<!-- {{ domainIsValid }} -->
								<button
									type="submit"
									class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
									@click="onSave"
									:class="domainIsValid && !registrationAlreadyExsists && !currentObj.domainUnavailable ? '' : 'bg-red-300 hover:bg-red-500'"
								>
									<!-- :class="domainIsValid && passwordIsValid && nameIsValid && !registrationAlreadyExsists ? '' : 'bg-red-300 hover:bg-red-500'" -->
									Create account
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="currentObj.showOk">
			<AdminUserSettingsOk />
		</div>
		<div v-if="currentObj.showFalse">
			<AdminUserSettingsFalse />
		</div>
	</div>
</template>
<script setup>
import { getCheckEmailByEmailAsync, getCheckEmailByEmailHash, updateEmailCheckAsync } from "@/api/email_check/email_check_api";
import { createRegistrationAsync, getRegistrationByEmailOrDomain, getRegistrationByEmailOrDomainAsync, getRegistrationPage } from "@/api/registration/registration_api";
import { computed, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import AdminUserSettingsOk from "./AdminUserSettingsOk.vue";
import AdminUserSettingsFalse from "./AdminUserSettingsFalse.vue";
import { debounce } from "@/utils/debounce";

const route = useRoute();

const currentObj = reactive({
	newAdmin: {},
	newInstance: {},
	foundRegistration: {},
	emailHash: null,
	showSettings: true,
	showOk: false,
	showFalse: false,
	domainUnavailable: false,
});

const props = defineProps({
	email: { type: String, default: "fake@fake.ff" },
});

const onDomainInputDebounce = debounce(function () {
	onDomainInput();
}, 400);

const onDomainInput = () => {
	// debugger;
	getRegistrationByEmailOrDomain(currentObj.newInstance.domain_prefix, onRegistrationByDomain, onErrorRegistrationByDomain);
};

const onRegistrationByDomain = (data) => {
	// debugger;
	if (data) {
		// debugger;
		currentObj.domainUnavailable = true;
	} else {
		currentObj.domainUnavailable = false;
	}
};

const onErrorRegistrationByDomain = (error) => {
	console.log(error);
};

onMounted(async () => {
	// debugger;
	console.log(route.params.hash);
	currentObj.emailHash = route.params.hash;

	try {
		const emailCheck = await getCheckEmailByEmailAsync(currentObj.emailHash);
		if (emailCheck) {
			currentObj.newAdmin.email = emailCheck.email;
			currentObj.foundRegistration = await getRegistrationByEmailOrDomainAsync(currentObj.newAdmin.email);
			if (currentObj.foundRegistration && currentObj.foundRegistration.domain_prefix) {
				// this registration is completed - and the only thing we need to do is to wait and see
				currentObj.showSettings = false;
				currentObj.showOk = true;
			} else {
			}
		} else {
			// it a corupted or bad hash
			currentObj.showSettings = false;
			currentObj.showFalse = true;
		}
	} catch (error) {
		if (error.response.status === 404) {
			// it a corupted or bad hash
			currentObj.showSettings = false;
			currentObj.showFalse = true;
		}
	}
});

const domainIsValid = computed(() => {
	// return currentObj.newInstance.domain_prefix != null && currentObj.newInstance.domain_prefix.match(/^[a-z0-9_-]+$/) ? true : false;
	return currentObj.newInstance.domain_prefix != null && currentObj.newInstance.domain_prefix.match(/^[a-z0-9_-]{8,}$/) ? true : false;
	// return strValue.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/) ? true : false;
});

const nameIsValid = computed(() => {
	return currentObj.newAdmin.name != null && currentObj.newAdmin.name.length > 2;
	// return strValue.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/) ? true : false;
});

const passwordIsValid = computed(() => {
	return currentObj.newAdmin.password != null && currentObj.newAdmin.password.length >= 8;
	// return strValue.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/) ? true : false;
});

const registrationAlreadyExsists = computed(() => {
	return currentObj.foundRegistration == null ? false : true;
	// return strValue.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/) ? true : false;
});

const onSave = async () => {
	if (domainIsValid.value && !registrationAlreadyExsists.value && !currentObj.domainUnavailable && nameIsValid.value) {
		console.log("send task to depoloy and create user");

		const newRegistration = {
			email: currentObj.newAdmin.email,
			password: currentObj.newAdmin.password,
			name: currentObj.newAdmin.name,
			full_name: currentObj.newAdmin.full_name,
			domain_prefix: currentObj.newInstance.domain_prefix,
		};

		// debugger;

		try {
			await createRegistrationAsync(newRegistration);
		} catch (error) {
			if (error.response.status === 409) {
				currentObj.showSettings = false;
				currentObj.showFalse = true;
				return;
			} else {
				// console.error("Error fetching data:", error);
				throw error;
			}
		}

		const emailCheck = await getCheckEmailByEmailAsync(currentObj.emailHash);
		emailCheck.checked = true;
		const newEmailCheck = await updateEmailCheckAsync(emailCheck);
		console.log(newEmailCheck);

		currentObj.showSettings = false;
		currentObj.showOk = true;

		getRegistrationPage(10, 0, "", onRegistrationPage);
	}
};

const onRegistrationPage = (data) => {
	console.log(data);
};
</script>
