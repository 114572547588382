<template>
	<div class="w-screen h-screen flex flex-col justify-center items-center">
		<div class="font-bold text-2xl text-center">Ушли делать!</div>
		<div class="text-gray-400 text-center">Скоро закончим: через пару минут проверьте почту, которая была использована при регистрации.</div>
	</div>

	<div v-if="false">
		<div>
			<h1>Instance settings are OK</h1>
		</div>
		<div class="w-screen">
			<div class="flex flex-row justify-center">
				<h2>Check your email in a while!</h2>
			</div>
		</div>
	</div>
</template>
