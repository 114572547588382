<template>
	<div class="w-screen h-screen flex flex-col justify-center items-center">
		<div class="font-bold text-2xl text-center">Что то пошло не так!</div>
		<div class="text-gray-400 text-center">Регистрация не удалась: попробуйте повторить это действие позже. Проверьте почту указанную при регистрации.</div>
	</div>

	<div v-if="false">
		<div>
			<h1>Bad news</h1>
		</div>
		<div class="w-screen">
			<div class="flex flex-row justify-center">
				<h2>Probably something went wrong.</h2>
			</div>
		</div>
	</div>
</template>
